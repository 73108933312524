import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Button, Modal } from '../../components';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY } from '../../util/types';
import config from '../../config';
import { EnquiryForm } from '../../forms';

import css from './ListingPage.module.css';

const SectionHeading = props => {
  const {
    authorDisplayName,
    title,
    priceTitle,
    formattedPrice,
    richTitle,
    category,
    address,
    showContactUser,
    onContactUser,
    onManageDisableScrolling,
    isEnquiryModalOpen,
    onCloseEnquiryModal,
    sendEnquiryError,
    onSubmitEnquiry,
    sendEnquiryInProgress,
    formattedPriceThree,
    formattedPriceTwo,
    formattedPriceOne
  } = props;


  const unitType = config.bookingUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'ListingPage.perNight'
    : isDaily
    ? 'ListingPage.perDay'
    : 'ListingPage.perUnit';

  return (
    <div className={css.sectionHeading}>
      <div className={css.heading}>
        <h1 className={css.topTitle}>{richTitle}</h1>
        <div className={css.author}>
          {category}
          {address}
        </div>
      </div>
      <div className={css.flexWrapper}>
        {showContactUser ? (
          <div className={css.contactWrapper}>
            <Button
              rootClassName={css.contactButton}
              onClick={onContactUser}
              enforcePagePreloadFor="SignupPage"
            >
              <FormattedMessage id="ListingPage.contactUser" />
            </Button>
          </div>
        ) : null}
        <div className={css.desktopPriceContainer}>
          <div className={css.desktopPriceValue} title={priceTitle}>
            {formattedPrice} <span className={css.desktopPerUnit}>/ month</span>
          </div>
          {/* <div className={css.desktopPerUnit}>
            <FormattedMessage id={unitTranslationKey} />
          </div> */}
          {formattedPriceThree ?
            <div className={css.desktopPriceValueSmall}>
              {formattedPriceThree} <span className={css.desktopPerUnit}>/ 3 days</span>
            </div>
          : null }
          {formattedPriceTwo ?
            <div className={css.desktopPriceValueSmall}>
              {formattedPriceTwo} <span className={css.desktopPerUnit}>/ 2 days</span>
            </div>
          : null }
          {formattedPriceOne ?
            <div className={css.desktopPriceValueSmall}>
              {formattedPriceOne} <span className={css.desktopPerUnit}>/ &nbsp; 1 day</span>
            </div>
          : null }
        </div>
      </div>
      <Modal
        id="ListingPage.enquiry"
        contentClassName={css.enquiryModalContent}
        isOpen={isEnquiryModalOpen}
        onClose={onCloseEnquiryModal}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <EnquiryForm
          className={css.enquiryForm}
          submitButtonWrapperClassName={css.enquirySubmitButtonWrapper}
          listingTitle={title}
          authorDisplayName={authorDisplayName}
          sendEnquiryError={sendEnquiryError}
          onSubmit={onSubmitEnquiry}
          inProgress={sendEnquiryInProgress}
        />
      </Modal>
    </div>
  );
};

export default SectionHeading;
