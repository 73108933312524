import React from 'react';
//import { shape, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { PropertyGroup } from '../../components';

import css from './ListingPage.module.css';

const SectionRulesMaybe = props => {
  const { options, publicData } = props;

  const selectedOptions = publicData && publicData.manifesto ? publicData.manifesto : [];
  return publicData && publicData.manifesto ? (
    <div className={css.sectionFeatures}>
      <h2 className={css.title}>
        <FormattedMessage id="ListingPage.manifestoTitle" />
      </h2>
      <PropertyGroup
        id="ListingPage.manifesto"
        options={options}
        selectedOptions={selectedOptions}
        twoColumns={true}
      />
    </div>
  ) : null;
};

//SectionRulesMaybe.defaultProps = { className: null, rootClassName: null };

// SectionRulesMaybe.propTypes = {
//   className: string,
//   rootClassName: string,
//   publicData: shape({
//     rules: string,
//   }),
// };

export default SectionRulesMaybe;
