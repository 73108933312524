import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { richText } from '../../util/richText';
import { findOptionsForSelectFilter } from '../../util/search';
import config from '../../config';

import css from './ListingPage.module.css';

const MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION = 20;

const SectionDescriptionMaybe = props => {

  const { description, publicData } = props;

  const findLabel = (categories, key) => {
    const cat = categories.find(c => c.key === key);
    return cat ? cat.label : key;
  };

  const teamSizeOptions = findOptionsForSelectFilter('team_size', config.custom.filters);
  const howLongOptions = findOptionsForSelectFilter('how_long', config.custom.filters);
  const workstyleOptions = findOptionsForSelectFilter('workstyle', config.custom.filters);
  const whenOptions = findOptionsForSelectFilter('when', config.custom.filters);

  const landlordTeamSize =
    publicData && publicData.landlordTeamSize ? (
      <span>
        {findLabel(teamSizeOptions, publicData.landlordTeamSize)}
      </span>
  ) : null;

  const landlordWorkstyle =
    publicData && publicData.landlordWorkstyle ? (
      <span>
        {findLabel(workstyleOptions, publicData.landlordWorkstyle)}
      </span>
  ) : null;

  const landlordLease =
    publicData && publicData.landlordLease ? (
      <span>
        {findLabel(howLongOptions, publicData.landlordLease)}
      </span>
  ) : null;

  const teamSize =
    publicData && publicData.teamSize ? (
      <span>
        {findLabel(teamSizeOptions, publicData.teamSize)}
      </span>
  ) : null;

  const howLong =
    publicData && publicData.howLong ? (
      <span>
        {findLabel(howLongOptions, publicData.howLong)}
      </span>
  ) : null;

  const workstyle =
    publicData && publicData.workstyle ? (
      <span>
        {findLabel(workstyleOptions, publicData.workstyle)}
      </span>
  ) : null;

  const when =
    publicData && publicData.when ? (
      <span>
        {findLabel(whenOptions, publicData.when)}
      </span>
  ) : null;

  return (
    <div className={css.sectionDescription}>
      <div className={css.options}>
        <div>
          <h2 className={css.title}>
            <FormattedMessage id="ListingPage.weAreTitle" />
          </h2>
          <p>1. TEAM SIZE: {landlordTeamSize} seats</p>
          <p>2. HOW LONG: Hold a lease for {landlordLease}</p>
          <p>3. WORKSTYLE: {landlordWorkstyle} </p>
          <p>4. AVAILABLE START DAY: {when} </p>
        </div>
        <div className={css.options2}>
          <h2 className={css.title}>
            <FormattedMessage id="ListingPage.lookingTitle" />
          </h2>
          <p>1. TEAM SIZE: {teamSize} seats</p>
          <p>2. HOW LONG: {howLong}</p>
          <p>3. WORKSTYLE: {workstyle} </p>
          <p>4. AVAILABLE START DAY: {when} </p>
        </div>
      </div>
      {description ? (
        <div>
          <h2 className={css.title}>
            <FormattedMessage id="ListingPage.descriptionTitle" />
          </h2>
          <p className={css.description}>
            {richText(description, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
              longWordClass: css.longWord,
            })}
          </p>
        </div>
      ) : null}
    </div>
  )
};

export default SectionDescriptionMaybe;
